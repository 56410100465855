function ButtonComponent() {
    return (
        <div className="ButtonComponent">
            <button
                className="buttonStyle"
                onClick={() => {
                    window.open('https://links.evergreen.de/registrieren', '_blank')
                }}
            >Neues Depot eröffnen
            </button>
        </div>
    );
}

export default ButtonComponent;