function ImpressumComponent() {
    return (
        <div className="ImpressumComponent">
            <a
                className="impressumLink"
                href="https://www.evergreen.de/imprint"
                target="_blank"
                rel="noopener noreferrer"
            >
                Impressum
            </a>
        </div>
    );
}

export default ImpressumComponent;