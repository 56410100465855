import {ReactComponent as ClockComponent} from "./clockImg.svg";
import "./App.css";
import TextComponent from "./components/TextComponent";
import ButtonComponent from "./components/ButtonComponent";
import ImpressumComponent from "./components/ImpressumComponent";

function App() {
    return (
        <div className="App">
            <TextComponent/>
            <ButtonComponent/>
            <ClockComponent className="clockSvg"/>
            <ImpressumComponent/>
        </div>
    );
}

export default App;
