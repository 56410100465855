import {useEffect, useState} from "react";


function TextComponent() {
    const [mode, setMode] = useState('light')

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change',
            e => onSelectMode(e.matches ? 'dark' : 'light'));

        onSelectMode(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light')

        return () => {
            window.matchMedia('(prefers-color-scheme: dark)').removeEventListener('change', () => {
            });
        }
    }, []);


    const onSelectMode = (mode) => {
        setMode(mode)
        if (mode === 'dark') document.body.classList.add('dark-mode');
        else document.body.classList.remove('dark-mode')
    }
    return (<div className="TextComponent">
        <div className="titleContainer">
            <div className="title">Die Zeit für Deinen Umzug ist abgelaufen</div>
            <div className="desc">
                Leider ist Dein Depotumzug von Vantik nicht mehr möglich. Dein Depot
                wird demnächst von der DAB BNP Paribas aufgelöst.
            </div>
        </div>
        <div className="subTitleContainer">
            <div className="subTitle">Du möchtest trotzdem weitersparen?</div>
            <div className="desc">
                Du kannst Dir bei EVERGREEN ein neues Depot eröffnen und dort
                weitersparen.
            </div>
        </div>
    </div>);
}

export default TextComponent;